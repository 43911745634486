export default {
  methods: {
    navigateToHome(roleName) {
      if (roleName === 'admin') {
        return this.$router.push({ name: 'admin-dashboard' })
      }
      if (roleName === 'borrower') {
        return this.$router.push({ name: 'customer-applications' })
      }
      if (roleName === 'staff' || roleName === 'manager') {
        return this.$router.push({ name: 'manage-applications' })
      }
      return this.$router.push({ name: 'clients' })
    },
  },
}
