<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        @click.native="$router.push('/')"
      >
        <div class="site-navbar-logo-box">
          <img
            :src="Logo"
            style="max-width:100px"
          >
          <h4 style="cursor:pointer">
            Mortgage <span class="logo-text">Envy</span> Corporation
          </h4>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Staff Login
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <div class="row">
                <div class="col-12">
                  <b-button
                    :disabled="loading"
                    type="submit"
                    variant="primary"
                    class="float-right"
                    @click="validationForm"
                  >
                    <b-spinner
                      v-if="loading"
                      small
                      type="grow"
                    />
                    {{ loading?'Loading...':'Login' }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link @click="()=>{$router.push({name:'staff-register'})}">
              <span>&nbsp;Create new account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>

    <b-modal
      id="modal-sm"
      v-model="showAccountType"
      cancel-variant="secondary"
      ok-only
      ok-title="Continue"
      centered
      size="sm"
      title="Account Type"
      :ok-disabled="type===null"
      @ok="goToRegister()"
      @close="()=>{showAccountType=false}"
    >
      <b-form-select
        v-model="type"
        placeholder="Select Account Type"
        :options="options"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BSpinner, BModal, BFormSelect, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { initialAbility } from '@/libs/acl/config'
import showToast from '@/mixins/showToast'
import navigateToHome from '@/mixins/navigateToHome'
import Logo from '@/assets/log2.png'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BModal,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility, showToast, navigateToHome],
  data() {
    return {
      Logo,
      is_admin: null,
      showAccountType: false,
      type: null,
      options: [{ text: 'Account Type', value: null }, { text: 'Client', value: 0 }, { text: 'Real Estate Agent', value: 1 }, { text: 'Staff', value: 3 }],
      loading: false,
      status: false,
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.is_admin = this.$route.path === '/admin/login'
  },
  methods: {
    login() {
      this.loading = true
      let path = 'auth/login'
      if (this.is_admin) {
        path = 'admin/login'
      }
      this.$http
        .post('/api/auth/login', {
          user_type: 'staff', email: this.userEmail, password: this.password, remember: this.status,
        })
        .then(res => {
          this.loading = false
          this.showSuccess('Logged in Successfully')
          this.$store.commit('auth/setUserData', res.data)
          const { roles } = res.data
          this.$ability.update(this.$store.getters['auth/ability'] || initialAbility)
          localStorage.setItem('isStaff', true)
          this.navigateToHome(res.data.user?.user_type)
        })
        .catch(err => {
          this.loading = false
          if (err?.status === 401) {
            this.showError('Invalid email or password')
          } else {
            this.showError('Failed to login, please try again')
          }
          console.log(err)
        })
    },
    showConfirm() {
      this.showAccountType = true
    },
    goToRegister() {
      let route
      switch (this.type) {
        case 0:
          route = 'register'
          break
        case 1:
          route = 'agent-register'
          break
        case 3:
          route = 'team-register'
          break
        default:
          route = 'register'
      }
      this.$router.push({ path: `/auth/${route}` })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.login()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
